@import "mixins";
@import "init";

.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 99% !important;
}
.chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  left: -9999px;
  z-index: 1010;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-top: 0;
     @include input-bg;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);

    .view-explore-jump-menu &,
    .views-exposed-form &{
      background-color: #FFF;
    }
}
.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
}
.chosen-container a {
  cursor: pointer;
}

/* @end */
/* @group Single Chosen */
.chosen-container-single .chosen-single {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0.54em 0.833em;
   @include input-bg;
  background-clip: padding-box;
  text-decoration: none;
  white-space: nowrap;
  line-height: 2em;
}
.chosen-container-single .chosen-default {
  color: #999;
}
.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}
.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}
.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url('../images/chosen-sprite.png') -42px 1px no-repeat;
  font-size: 1px;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-single div {
  position: absolute;
  top: 1px;
  right: 1px;
  display: block;
  width: 36px;
  height: 100%;
  border-left: 1px solid #fff;
}
.chosen-container-single .chosen-single div b {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent url('../images/blue-arrow-down.svg') no-repeat center 1em;
  background-size: 9px 10px;
}
.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap;
}

.chosen-container-single .chosen-search input[type="text"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 1px 0;
  padding: 8px 20px 8px 5px;
  width: 100%;
  line-height: 1.3em;
  height: auto;
  outline: 0;
  background: white url('../images/chosen-mag-glass.png') no-repeat 98% 10px;
  background: url('../images/chosen-mag-glass.png') no-repeat 98% 10px;
  font-size: 1em;
  font-family: sans-serif;
  border: 1px solid #ccc;
}
.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
  background-clip: padding-box;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  left: -9999px;
}

/* @end */
/* @group Results */
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0 0 4px;
  max-height: 240px;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  margin: 0;
  padding: 8px;
  list-style: none;
  line-height: 18px;
  -webkit-touch-callout: none;
  color: #333;
  font-size: 11px;
  line-height: 1.6em;
}
.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #16c1f3;
  background-image: none;
  color: #fff;
}
.chosen-container .chosen-results li.no-results {
  display: list-item;
  background: #f4f4f4;
}
.chosen-container .chosen-results li.group-result {
  display: list-item;
  font-weight: bold;
  cursor: default;
}
.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}
.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}

/* @end */
/* @group Multi Chosen */
.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 5px;
  width: 100%;
  height: auto !important;
  height: 1%;
  box-shadow: 0px 0px 7px 0px #E0E0E0 inset;
  background-color: #f4f4f4;
  cursor: text;
}
.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}
.chosen-container-multi .chosen-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  margin: 1px 0;
  padding: 5px;
  outline: 0;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none;
  color: #666;
  font-size: 100%;
  line-height: normal;
  border-radius: 0;
}
.chosen-container-multi .chosen-choices li.search-field .default {
  color: #999;
}
.chosen-container-multi .chosen-choices li.search-choice {
  position: relative;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  border-radius: 3px;
  color: #444;
  line-height: 16px;
  border: 1px solid #D1D1D1;
  background-color: #E4E4E4;
  background-clip: padding-box;
  cursor: default;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 6px;
  right: 3px;
  display: block;
  width: 12px;
  height: 12px;
  background: url('../images/chosen-sprite.png') -42px 1px no-repeat;
  font-size: 1px;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-choices li.search-choice-disabled {
  padding-right: 5px;
  border: 1px solid #ccc;
  background-color: #e4e4e4;
  color: #666;
}
.chosen-container-multi .chosen-choices li.search-choice-focus {
  background: #d4d4d4;
}
.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0;
}
.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  color: #ccc;
  cursor: default;
}


/* @end */
/* @group Active  */
.chosen-container-active .chosen-single {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.chosen-container-active.chosen-with-drop .chosen-single {

   @include border-radius(4px 4px 0 0);
   @include input-bg;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-image: url('../images/blue-arrow-up.svg');
}
.chosen-container-active .chosen-choices {
  line-height: 2em;
  box-shadow: 0px 0px 7px 0px #E0E0E0 inset;
background: #F4F4F4 none repeat scroll 0% 0%;
}
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
  color: #111 !important;
}

/* @end */
/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

/* @end */
/* @group Right to Left */
.chosen-rtl {
  text-align: right;
}
.chosen-rtl .chosen-single {
  overflow: visible;
  padding: 0 8px 0 0;
}
.chosen-rtl .chosen-single span {
  margin-right: 0;
  margin-left: 26px;
  direction: rtl;
}
.chosen-rtl .chosen-single-with-deselect span {
  margin-left: 38px;
}
.chosen-rtl .chosen-single div {
  right: auto;
  left: 3px;
}
.chosen-rtl .chosen-single abbr {
  right: auto;
  left: 26px;
}
.chosen-rtl .chosen-choices li {
  float: right;
}
.chosen-rtl .chosen-choices li.search-field input[type="text"] {
  direction: rtl;
}
.chosen-rtl .chosen-choices li.search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}
.chosen-rtl .chosen-choices li.search-choice .search-choice-close {
  right: auto;
  left: 4px;
}
.chosen-rtl.chosen-container-single-nosearch .chosen-search,
.chosen-rtl .chosen-drop {
  left: 9999px;
}
.chosen-rtl.chosen-container-single .chosen-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}
.chosen-rtl .chosen-results li.group-option {
  padding-right: 15px;
  padding-left: 0;
}
.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: none;
}
.chosen-rtl .chosen-search input[type="text"] {
  padding: 4px 5px 4px 20px;
  background: white url('../images/chosen-sprite.png') no-repeat -30px -20px;
  background: url('../images/chosen-sprite.png') no-repeat -30px -20px;
  direction: rtl;
}
.chosen-rtl.chosen-container-single .chosen-single div b {
  background-position: 6px 2px;
}
.chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
  background-position: -12px 2px;
}

/* @end */
/* @group Retina compatibility */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background: transparent url("../images/blue-arrow-down.svg") no-repeat 0 right;
    background-size: 9px 10px;
  }
  .chosen-container-single .chosen-search input[type="text"] {
      background: url("../images/chosen-mag-glass.png") no-repeat 98% 10px;
      }
}
/* @end */
