// @file
// Custom sass mixins
//
// Define the custom mixins for your project here.
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  color: inherit !important; // Inherit the parents colour to keep contrast.
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
  display: block;
  padding: 1em;
  background-color: $color-highlight;
  color: $color-highlight-contrast;
  z-index: 10;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}


@mixin noSpace {
  margin: 0;
  padding: 0;
}

@mixin blue-arrow-list {
    color: #16c1f3;
     li {
      color: #5c626c;
      background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left center;
      background-size: 10px 9px;
      list-style: none;
      padding-left: 20px;
      margin-top: 7px;
      margin-bottom: 7px;
      font-size: 13px;
      line-height: 16px;

       a {
        color: #1f4ea1;

          &:hover {
            text-decoration: underline;
          }
       }
     }
}

@mixin underlineOnHover {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

@mixin standard-button {
  border: 1px solid #24ace0;
  color:  #fff;
  text-align: center;
  display: inline-block;
  margin-top: 2em;
  font-weight: 600;
  padding: 0.3846em 1em;
  background-position: 0.75em center;
  line-height: 2em;
  -webkit-appearance: none;
}

@mixin standard-button-hover {
  &:hover {
    background-color: #21a4d6;
  }
}

%pseudo-block {
  content: "";
  display: block;
}

@mixin input-bg {
  background-color: #EFEFEC;
  font-size: 0.8461em;
  color: #333;
  border: none;
}

@mixin button-reverse {
  opacity: 0.9;
}

@mixin blue-bg {
  background: linear-gradient(to right,  #225cad 0%,#2799d7 100%);
}

@mixin button-blue {
  span {
    color: #fff;
    font-size: 0.8461em;
    line-height: 1.3em;
    padding: 4px 10px 4px 16px;
    background: transparent url("../images/green-arrow.svg") no-repeat scroll left center;
    background-size: 10px 10px;
    display: inline-block;

     .large-fonts & {
      font-size: 0.75em;
     }
  }
  background: linear-gradient(to right,  #204ea3 0%,#27a8df 100%);
  display: block;
  text-align: right;

   &:hover {
    text-decoration: none;
    @include image-hover();
   }
}

@mixin green-box {
  background: linear-gradient(to right,  #63ae6b 0%,#90ba56 100%);
  position: absolute;
  font-size: 0.8461em;
  line-height: 1.89091em;
  padding: 0 8px;
  color: #fff;
}

@mixin read-more {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
 font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;

   &:hover {
    text-decoration: underline !important;
   }
}

@mixin input-round {
  background-color: #24ace0;
  height: 3em;
  border: none;
  color: #fff;
  padding: 0;
  text-transform: uppercase;
 font-size: 0.8461em;
  line-height: 3.1em;
  @include border-radius(1.5em);
}

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin control-nav {
   background-color: #e9edf6;
    margin: 0;
    overflow: hidden;

    label {
      margin-bottom: 11px;
    }

    ul {
      margin-top: 0;
    }

  button {
    background-size: 10px 9px !important;
    text-transform: lowercase;
    list-style: none;
    font-size: 11px;
    font-weight: 600;
    padding-top: 6px;
    padding-bottom: 6px;
    outline: 0;
    line-height: 1.3em;
    border: none;
    color: #1f4ea1;

    &:hover {
      color: #333;
      cursor: pointer;
    }


    &:first-child{
       background: transparent url("../images/blue-arrow-right.svg") no-repeat scroll left center;
       float: left !important;
       padding-left: 15px;
       margin-left: 10px;
       width: 6em;
    }
    &:last-child {
      background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll right center;
      float: right !important;
      padding-right: 10px;
      margin-right: 10px;
      width: 4.3em;
    }
  }
}

@mixin image-hover {
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
    transition: filter .1s;
}

@mixin footer-link {
  padding: 6px 12px 0;
  text-align: right;
  a {
    padding-left: 17px;
  }
}

@mixin field-title {
  min-height: 2.9em;
   a {
    @include read-more();
   }
}

@mixin zen-clear(
  $float-direction      : $zen-float-direction,
  $reverse-all-floats   : $zen-reverse-all-floats
) {
  // Determine the float direction.
  $dir: $float-direction;
  @if $reverse-all-floats {
    $dir: zen-direction-flip($dir);
  }
  clear: $dir;
}